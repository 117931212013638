    <template>
    <!-- <div class="hidepage" @click="hideClick()"></div> -->
    <div class="row container closebuttonContainer">
    <div class="col-6"></div>
    <div class="col-6">
    <p class="closeButton " @click="routeBack()"><font-awesome-icon :icon="['fas','times']" /></p>
    </div>
    </div>
    <div class="container">
    <div class="text-align text-center py-3" @click="showSignupBtn">
    <p class ="userBtn mb-0" >New user? Sign up</p>
    </div>
    <div v-if="newSignupBtn">
    <div class="text-align text-center py-2"  v-if="showdoctorbtn && queryParameters.card != 'public'">
    <button class="SignupWhiteBtn btn " :disabled="!disableUserBtn || !disableGeneralBtn" @click="showDoctorBox">Sign up as doctor</button>
    </div>
    <fieldset class="signUpField my-3"  v-if="showDoctorFeild">
    <legend > Sign up as doctor</legend>
    <div v-if="doctorSignup">
    <div class="py-2">
    <label class="sign-up-feilds"> Mobile number</label>
    <input class="form-control" type="text" maxlength="10" aria-label="mobileNo" @input="mobileNumberValidateHcp" v-model="mobileHcp" v-on:keyup.enter="isMobileValidHcp && timegreaterHcp ? generateOTPHcp() : null" />
    </div>
    <div class="form-group text-center py-3" v-if="generateOTP == false && !enableResendBtnHcp">
    <button class="btn btn-blue-color text-white" :disabled="!isMobileValidHcp || !timegreaterHcp" v-bind:class="[!isMobileValidHcp ? 'disableBtn' : '']"   
    @click="generateOTPHcp()">
    Generate OTP
    </button>
    </div>
    <div class="form-group text-align text-center py-3" v-if="enableResendBtnHcp" >
    <button class="btn btn-color text-white tex-align text-center" :disabled="!isMobileValidHcp ||!timegreaterHcp" v-bind:class="[!isMobileValidHcp ? 'disableBtn' : '']" @click="resendOTPHcp">Resend OTP</button>
    </div>
    <div v-if="sendOTPHcp ">
    <div class="col sign-otp" >
    <div class="inputs d-flex flex-row justify-content-center" ref="otpBoxHcp">
    <input v-for="(numbers,index) in otpNumbersHcp" :key="index"  class="m-2 text-center form-control rounded" type="text" maxlength="1" v-model="otpNumbersHcp[index]" @input="validateInputHcp(index)" v-on:keyup.enter="combinedOTPHcp ? verityOTPHcp() : null" />
    </div>
    </div>
    <div>
    <p v-if="timeLeftHcp > 0" >Resend OTP in <span id="countdowntimer">{{ timeLeftHcp }}</span> Seconds</p>
    </div>
    <div class="text-align text-center">
    <button class="btn btn-blue-color text-white tex-align text-center" :disabled="!combinedOTPHcp"  v-bind:class="[!combinedOTPHcp ? 'disableBtn' : '']" @click="verityOTPHcp()">
    Verify
    </button>
    </div>
    </div>
    <div class=" text-align text-center">
    <p class="mb-0 termsFont">By continuing, you agree to our <span class="cursorPointer"
    @click="openTermsConditionHCP()"><u>Terms</u></span> and <span class="cursorPointer"
    @click="openPrivacyPolicy()"> <u> Privacy policy </u></span>
    and confirm you are 22 years or older.
    </p>
    </div>
    </div>
    <div v-if="doctorOtpSuccess">
    <div>
    <p class="text-align text-center sign-up-in-text">Tell us about yourself</p>
    </div>
    <div class="row">
    <div class="py-2 col-12 ">

    <label class="sign-up-feilds">First Name</label>
    <div class="form-group input-group">
    <span class="input-group-addon">Dr.</span>
    <input type="text" class="form-control" id="formName" aria-label="firstName" v-model="firstNameDoctor" v-on:keyup.enter="isFormValidHCP ? registerDoctor('hcp') : null">
    </div>
    </div>
    </div>
    <div class="row">
    <div class="py-2 col-12">
    <label class="sign-up-feilds"> Last Name</label>
    <input class="form-control" type="text" aria-label="lastName" v-model="lastNameDoctor" v-on:keyup.enter="isFormValidHCP ? registerDoctor('hcp') : null" />
    </div>
    </div>
    <div class="row">
    <div class="py-2 col-6">
    <label class="sign-up-feilds">Age (years)</label>
    <input class="form-control" type="text" aria-label="age" v-model="doctorage" @input="validateAgeDoctor"
    v-bind:class="[!doctorage || doctorage >= 22 ? '' : 'redborder']" v-on:keyup.enter="isFormValidHCP ? registerDoctor('hcp') : null" />
    </div>
    <div class="py-2 col-6">
    <label class="sign-up-feilds">Gender</label>
    <div>
    <select class="form-control" name="gender" id="gender" v-model="selectedGender" v-on:keyup.enter="isFormValidHCP ? registerDoctor('hcp') : null">
    <option >   Male</option>
    <option>  Female </option>
    <option>Others</option>
    </select>
    </div>
    </div>
    </div>
    <span>Note: Minimum 22 years</span>

    <div class="row">
    <div class="py-2 col-12">
    <label class="sign-up-feilds">Pincode</label>
    <input class="form-control" aria-label="pincode" type="search" @search="getAllPincode"
    v-model="pincodeDoctor" maxlength="6" :options="pincodedata" v-on:keyup.enter="isFormValidHCP ? registerDoctor('hcp') : null">
    </div>
    </div>
    <div class="form-group text-center py-3">
    <button class="btn btn-blue-color text-white" v-if="generateOTP == false" @click="registerDoctor('hcp')"
    :disabled="!isFormValidHCP" v-bind:class="[!isFormValidHCP ? 'disableBtn' : '']">
    Create my account
    </button>
    <p class="allfieldcontent">All fields are mandatory</p>
    </div>
    </div>
    </fieldset>
    <div class="text-align text-center py-2" v-if="showpublicbtn&& queryParameters.card != 'doctor'">
    <button class="SignupWhiteBtn btn " :disabled="!disableHcpBtn || !disableGeneralBtn"  @click="showPubicBox">Sign up as public</button>
    </div>
    <fieldset class="signUpField my-3 "  v-if="showPubicFeild" >
    <legend > Sign up as public</legend>
    <div v-if="publicSignup">
    <div class="py-2">
    <label class="sign-up-feilds"> Mobile number</label>
    <input class="form-control" type="text" aria-label="mobileNo" maxlength="10"  @input="mobileNumberValidateUser" v-model="mobileUser" v-on:keyup.enter="isMobileValidUser && timegreaterUser ? generateOTPUser() : null" />
    </div>
    <div class="form-group text-center py-3" v-if="generateOTP == false && !enableResendBtnUser">
    <button class="btn btn-blue-color text-white"  :disabled="!isMobileValidUser || !timegreaterUser"
    @click="generateOTPUser()" v-bind:class="[!isMobileValidUser ? 'disableBtn' : '']">
    Generate OTP
    </button>
    </div>
    <div class="form-group text-align text-center py-3" v-if="enableResendBtnUser" >
    <button class="btn btn-color text-white tex-align text-center"  :disabled="!isMobileValidUser || !timegreaterUser" v-bind:class="[!isMobileValidUser ? 'disableBtn' : '']" @click="resendOTPUser">Resend OTP</button>
    </div>
    <div v-if="sendOTPUser">
    <div class="col sign-otp" >
    <div class="inputs d-flex flex-row justify-content-center" ref="otpBoxUser">
    <input v-for="(number,index) in otpNumbersUser" :key="index"   class="m-2 text-center form-control rounded" type="text" maxlength="1"
    v-model="otpNumbersUser[index]" @input="validateInputUser(index)" v-on:keyup.enter="combinedOTPUser ? verityOTPUser() : null" />
    </div>
    </div>
    <div>
    <p v-if="timeLeftUser > 0" >Resend OTP in <span id="countdowntimer">{{ timeLeftUser }}</span> Seconds</p>

    </div>
    <div class="text-align text-center">
    <button class="btn btn-blue-color text-white tex-align text-center" :disabled="!combinedOTPUser" v-bind:class="[!combinedOTPUser ? 'disableBtn' : '']" @click="verityOTPUser()">
    Verify
    </button>
    </div>

    </div>
    <div class=" text-align text-center">
    <p class="mb-0 termsFont">By continuing, you agree to our <span class="cursorPointer"
    @click="openTermsConditionUser">
    <u>Terms</u>
    </span> and <span class="cursorPointer" @click="openPrivacyPolicy"> <u> Privacy policy </u></span> and
    confirm you are
    18 years or older.
    </p>
    </div>
    </div>
    <div v-if="publicOtpSuccess">
    <div>
    <p class="text-align text-center sign-up-in-text">Tell us about yourself</p>
    </div>
    <div class="row">
    <div class="py-2 col-12 ">
    <label class="sign-up-feilds">First Name</label>
    <input class="form-control " type="text" aria-label="firstName" v-model="firstNamePublic" v-on:keyup.enter="isFormValidUser ? registerPublic('user') : null" />
    </div>
    </div>
    <div class="row">
    <div class="py-2 col-12">
    <label class="sign-up-feilds"> Last Name</label>
    <input class="form-control" type="text" aria-label="lastName" v-model="lastNamePublic" v-on:keyup.enter="isFormValidUser ? registerPublic('user') : null" />
    </div>
    </div>
    <div class="row">
    <div class="py-2 col-6">
    <label class="sign-up-feilds">Age (years)</label>
    <input class="form-control" type="text" aria-label="age" v-model="publicage" @input="validateAgePublic"
    v-bind:class="[!publicage || publicage >= 18 ? '' : 'redborder']" v-on:keyup.enter="isFormValidUser ? registerPublic('user') : null" />
    </div>
    <div class="py-2 col-6">
    <label class="sign-up-feilds">Gender</label>
    <div>
    <select class="form-control" name="gender" id="gender" v-model="selectedGender" v-on:keyup.enter="isFormValidUser ? registerPublic('user') : null">
    <option> Male</option>
    <option> Female </option>
    <option>  Others </option>
    </select>
    </div>
    </div>
    <span>Note: Minimum 18 years</span>
    </div>
    <div class="row">
    <div class="py-2 col-12">
    <label class="sign-up-feilds">Pincode</label>
    <input class="form-control" aria-label="pincode" type="search" @search="getAllPincode"
    v-model="pincodePublic" maxlength="6" :options="pincodedata" v-on:keyup.enter="isFormValidUser ? registerPublic('user') : null">
    </div>
    </div>
    <div class="form-group text-center py-3">
    <button class="btn btn-blue-color text-white" v-if="generateOTP == false" @click="registerPublic('user')"
    :disabled="!isFormValidUser" v-bind:class="[!isFormValidUser ? 'disableBtn' : '']">
    Create my account
    </button>
    <p class="allfieldcontent">All fields are mandatory</p>
    </div>
    </div>
    </fieldset>
    </div>
    <div class="text-align text-center pt-3">
    <p class ="userBtn mb-0"  @click="showSigninBox()" :disabled="!disableHcpBtn || !disableUserBtn" :class="[!disableHcpBtn || !disableUserBtn ? 'disable-btn' : '']">Existing user? Sign in</p>
    </div>
    <section class="p-3 form" v-if="hideIfMobileVerifed" v-bind:class="[hideIfMobileVerifed ? 'signupBox' : '']">
    <div class="py-2">
    <label class="sign-up-feilds"> Mobile number</label>
    <input class="form-control" type="text" aria-label="mobileNo" maxlength="10"   @input="mobileNumberValidateGeneral" v-model="mobileGeneral" v-on:keyup.enter="isMobileValidGeneral && timegreaterGeneral ? generateOTPGeneral() : null" />
    </div>
    <div class="form-group text-center py-3" v-if="generateOTP == false && !enableResendBtnGeneral">
    <button class="btn btn-blue-color text-white" :disabled="!isMobileValidGeneral|| !timegreaterGeneral"   
    @click="generateOTPGeneral()" v-bind:class="[!isMobileValidGeneral ? 'disableBtn' : '']" >
    Generate OTP
    </button>
    </div>
    <div class="form-group text-align text-center py-3" v-if="enableResendBtnGeneral" >
    <button class="btn btn-color text-white tex-align text-center" v-bind:class="[!isMobileValidGeneral ? 'disableBtn' : '']" :disabled="!isMobileValidGeneral|| !timegreaterGeneral" @click="resendOTPGeneral">Resend OTP</button>
    </div>
    <div v-if="sendOTPGeneral ">
    <div class="col sign-otp" >
    <div class="inputs d-flex flex-row justify-content-center" ref="otpBoxGeneral">
    <input v-for="(numbers,index) in otpNumbersGeneral" :key="index" class="m-2 text-center form-control rounded" type="text" maxlength="1"
    v-model="otpNumbersGeneral[index]" @input="validateInputGeneral(index)" v-on:keyup.enter="combinedOTPGeneral ? verityOTPGeneral() : null"
    />
    </div>
    </div>
    <div>
    <p v-if="timeLeftGeneral > 0" >Resend OTP in <span id="countdowntimer">{{ timeLeftGeneral }}</span> Seconds</p>
    </div>
    <div class="text-align text-center">
    <button class="btn btn-blue-color text-white tex-align text-center" v-bind:class="[!combinedOTPGeneral ? 'disableBtn' : '']" :disabled="!combinedOTPGeneral" @click="verityOTPGeneral()">
    Verify
    </button>
    </div>
    </div>
    </section>
    <div class="py-2 text-align text-center" v-if="showAlertTxt && !newSignupBtn">
    <h5 style="color:rgb(239, 63, 63)" >The number is not registered with us. Please sign up.</h5>
    </div>
    <section class="p-3 mt-5 form signupBox"  v-if="isAdmin">
    <div class="modal-dialog">
    <div class="modal-content" >
    <div class="modal-header" >
    <h5 class="modal-title" id="staticBackdropLabel">{{ adminQA.questions }}</h5>
    </div>
    <div class="modal-body" >
    <label >Answer below:</label>
    <input  type="password" v-model="adminAnswaer" @keyup.enter="getAnswer()">
    </div>
    <div class="modal-footer">
    <button type="button" class="btn btn-primary " style="margin-right: 3px; margin-top: 10px;"  @click="getAnswer()">Save</button>
    <button type="button" class="btn btn-danger " style="margin-left: 3px;margin-top: 10px;" @click="closeQuestionBox()">Close</button>
    </div>
    </div>
    </div>
    </section>
    </div>
    </template>
<script>
import axios from 'axios'
import Swal from 'sweetalert2/dist/sweetalert2.js'
export default {
  components: {},
  data() {
    return {
      mobileHcp: "",
      mobileUser:"",
      mobileGeneral:"",
      pincodedata: [],
      pincodeDoctor: "",
      pincodePublic: "",
      firstNameDoctor: '',
      firstNamePublic: '',
      lastNameDoctor: "",
      lastNamePublic: "",
      agree: "",
      doctorage: '',
      publicage: '',
      gender: '',
      currentRoute: "",
      generateOTP: false,
      otp: [null, null, null, null],
      isprove: false,
      practiceId: "",
      doctorPatientId: "",
      hideIfMobileVerifed: false,
      payload: {},
      doctorRegisterPayload:{},
      userRegisterPayload:{},
      doctorPayload: {},
      userPayload: {},
      adminPayload: {},
      doctorPracticeArea: [],
      doctorPatientGroups: [],
      doctorLibraryPracticeArea:[],
      doctorBio: [],
      selectedGender: '',
      receiveconsultationData: {},
      actDoctor: true,
      actPublic: false,
      ConsultationPracticeArea:[],
      getPracticeAreaRoute:'',
      queryParameters:{},
      doctorConsultations:[],
      doctorLibraryId:Number,
      doctorPatientGroupId:Number,
      doctorReceiveConsultationId:Number,
      doctorGetCaseId:Number,
      doctorOfferConsultationId:Number,
      isAdmin:false,
      adminQuestionData:[],
      adminQA:{},
      adminAnswaer:"",
      otpNumbersHcp: ['', '', '', ''],
      otpNumbersUser: ['', '', '', ''],
      otpNumbersGeneral: ['', '', '', ''],
      sendOTPHcp:'',
      sendOTPUser:'',
      sendOTPGeneral:false,
      timeLeftHcp: 30,
      timeLeftUser: 30,
      timeLeftGeneral: 30,
      timerHcp: null,
      timerUser: null,
      timerGeneral: null,
      enableResendBtnHcp:false,
      enableResendBtnUser:false,
      enableResendBtnGeneral:false,
      newSignupBtn:false,
      doctorSignup:false,
      publicSignup:false,
      showdoctorbtn:true,
      showpublicbtn:true,
      searchClinicId:Number,
      publicDoctorCommunityId:Number,
      publicPatientGroupId:Number,
      publicLibraryId:Number,
      publicMedicalId:Number,
      publicPatientGroupsData: [],
      publicDoctorCommunityData: [],
      getDisorderArray: [],
      doctorClinicData: [],
      doctorHomePincodeData: [],
      doctorHomeData:[],
      indexData:{},
      eventData:{},
      timegreaterHcp:true,
      timegreaterGeneral:true,
      timegreaterUser:true,
      disableGeneralBtn:true,
      disableHcpBtn:true,
      disableUserBtn:true,
      doctorRandomUUID:'',
      publicRandomUUID:'',
      adminRandomUUID:'',
      doctorUniqueID:'',
      adminUniqueID:'',
      publicUniqueID:''
    }
  },
 
  mounted: function () {
    this.getAllPincode()
    this.currentRoute = this.$route.path.split('/')[2];
  },
  computed: {
    combinedOTPHcp() {
       if (this.otpNumbersHcp.some(input => !/^\d$/.test(input))) {
        return '';
      }
      return this.otpNumbersHcp.join('');
    },
    combinedOTPUser() {
      if (this.otpNumbersUser.some(input => !/^\d$/.test(input))) {
        return '';
      }
      return this.otpNumbersUser.join('');
    },
    combinedOTPGeneral() {
       if (this.otpNumbersGeneral.some(input => !/^\d$/.test(input))) {
        return '';
      }
      return this.otpNumbersGeneral.join('');
    },
    isMobileValidHcp() {
      const mobilevalidation = /^[6-9]\d{9}$/;
      return mobilevalidation.test(this.mobileHcp);
    },
    isMobileValidGeneral() {
      const mobilevalidation = /^[6-9]\d{9}$/;
      return mobilevalidation.test(this.mobileGeneral);
    },
    isMobileValidUser() {
      const mobilevalidation = /^[6-9]\d{9}$/;
      return mobilevalidation.test(this.mobileUser);
    },

    isFormValidHCP() {
      const pincodeValidation = /^[1-9](\s*\d){5}$/;
      const nameRegex = /^[A-Za-z.\s]+$/;
      return (
        nameRegex.test(this.firstNameDoctor) &&
        nameRegex.test(this.lastNameDoctor) &&
        this.doctorage >= 22 && this.doctorage <= 999 &&
        this.selectedGender &&
        pincodeValidation.test(this.pincodeDoctor)
      );
    },
    isFormValidUser() {
      const pincodeValidation = /^[1-9](\s*\d){5}$/;
      const nameRegex = /^[A-Za-z.\s]+$/;
      return (
        nameRegex.test(this.firstNamePublic) &&
        nameRegex.test(this.lastNamePublic) &&
        this.publicage >= 18 && this.publicage <= 999 &&
        this.selectedGender &&
        pincodeValidation.test(this.pincodePublic)
      );
    },
  },
  created:  async function (){
   this.doctorRandomUUID=crypto.randomUUID();
   this.adminRandomUUID=crypto.randomUUID();
   this.publicRandomUUID=crypto.randomUUID();

   this.doctorUniqueID = localStorage.getItem('doctor-uniqueId')
   this.adminUniqueID = localStorage.getItem('admin-uniqueId')
   this.publicUniqueID = localStorage.getItem('public-uniqueId')

    this.queryParameters = this.$route.query;
    if (this.queryParameters.routeName === 'joinpatient') {
      this.doctorPatientGroupId = JSON.parse( this.queryParameters.DoctorPatientGroupID)
      this.doctorPatientGroups.push(JSON.parse(this.queryParameters.patientGroup))
    }
    if (this.queryParameters.routeName === 'receiveconsultation') {
      this.doctorReceiveConsultationId = JSON.parse(this.queryParameters.receiveConsultationId)
      this.doctorConsultations=JSON.parse(this.queryParameters.consultation);
      this.ConsultationPracticeArea=JSON.parse(this.queryParameters.practiceId);
      this.firstNameDoctor = this.queryParameters.firstName
      this.lastNameDoctor = this.queryParameters.lastName
      this.pincodeDoctor = this.queryParameters.pincode
    }
    if (this.queryParameters.routeName === 'getcase') {
      this.doctorGetCaseId = JSON.parse(this.queryParameters.practiceAreaId)
      this.doctorPracticeArea.push( JSON.parse(this.queryParameters.practicearea));
    }
    if (this.queryParameters.routeName === 'offerconsultation') {
      this.doctorOfferConsultationId = JSON.parse(this.queryParameters.offerConsultationID)
    }
    if (this.queryParameters.routeName === 'accessclinical'){
      this.doctorLibraryId = JSON.parse(this.queryParameters.doctorLibraryID)
      this.doctorLibraryPracticeArea.push(JSON.parse(this.queryParameters.PracticeId))
    }

    if (this.queryParameters.firstName && this.queryParameters.status) {
      this.queryParameters.id = Number(this.queryParameters.id);
      this.doctorBio.push(this.queryParameters);
    }
    this.queryParameters = this.$route.query;
    if (this.queryParameters.routeName === 'previewPage-clinic') {
      this.doctorClinicData.push( JSON.parse(this.queryParameters.doctorClinicDetails));
      this.searchClinicId= JSON.parse(this.queryParameters.searchClinicId)
    }
    if(this.queryParameters.routeName === 'previewPage-home'){
      this.doctorHomeData.push(JSON.parse(this.queryParameters.homeClinic));
      this.doctorHomePincodeData.push(JSON.parse(this.queryParameters.homeClinicPincode))
      this.searchClinicId= JSON.parse(this.queryParameters.searchClinicId)
    }
    if (this.queryParameters.routeName === 'joindoctorscommunity') {
      this.publicDoctorCommunityData.push( JSON.parse(this.queryParameters.doctorCommunityData));
      this.publicDoctorCommunityId= JSON.parse(this.queryParameters.doctorCommunityId)
    }
    if (this.queryParameters.routeName === 'joinpatientgroups') {
      this.publicPatientGroupsData.push( JSON.parse(this.queryParameters.patientGroupData));
      this.publicPatientGroupId= JSON.parse(this.queryParameters.publicPatientGroupId)
    }
    if (this.queryParameters.routeName === 'medi-records') {
      this.publicMedicalId= JSON.parse(this.queryParameters.publicMediId)
    }
    if (this.queryParameters.routeName === 'accessreliableinformation') {
      this.getDisorderArray=[]
      const originalResponse = JSON.parse(this.queryParameters.publcLibraryData);
      const transformedResponse = {
        "id": parseInt(originalResponse.disorder_id),
        "disorder_id": originalResponse.disorder_id,
        "disorder": decodeURIComponent(originalResponse.disorder)
      };
      this.getDisorderArray.push(transformedResponse);
      this.publicLibraryId= JSON.parse(this.queryParameters.publcLibraryId)
    }
    this.getDoctorHistory();
  },
  methods: {
    showSignupBtn(){
      this.newSignupBtn=true
      this.hideIfMobileVerifed=false
    },
    showSigninBox(){
      this.showAlertTxt=false
      this.hideIfMobileVerifed=true;
      this.newSignupBtn=false;
      this.showDoctorFeild=false;
      this.showPubicFeild=false;
      this.showdoctorbtn=true
      this.showpublicbtn=true
      this.mobileHcp='';
      this.mobileUser='';
      this.mobileGeneral=''
    },
    showDoctorBox(){
      this.doctorSignup=true
      this.showdoctorbtn=false
      this.showDoctorFeild=true
      this.showPubicFeild=false
      this.showpublicbtn=true
      this.publicOtpSuccess=false
    },
    showPubicBox(){
      this.publicSignup=true
      this.showpublicbtn=false
      this.showPubicFeild=true
      this.showDoctorFeild=false
      this.showdoctorbtn=true
      this.doctorOtpSuccess=false
    },
    mobileNumberValidateHcp(){
      this.mobileHcp = this.mobileHcp.replace(/\D/g, '');
    },
    mobileNumberValidateGeneral(){
      this.mobileGeneral = this.mobileGeneral.replace(/\D/g, '');
    },
    mobileNumberValidateUser(){
      this.mobileUser = this.mobileUser.replace(/\D/g, '');
    },
    validateInputHcp(index) {
      const inputValue = this.otpNumbersHcp[index];
      const isValid = /^\d$/.test(inputValue);
      if (!isValid) {
        this.otpNumbersHcp[index] = ''; // Clear the input if non-numeric character entered
      }
      if (this.otpNumbersHcp[index] !== '' && index < this.otpNumbersHcp.length - 1) {
        this.$refs.otpBoxHcp.querySelectorAll('input')[index + 1].focus();
    }
    },
    validateInputGeneral(index) {
      const inputValue = this.otpNumbersGeneral[index];
      const isValid = /^\d$/.test(inputValue);
      if (!isValid) {
        this.otpNumbersGeneral[index] = ''; // Clear the input if non-numeric character entered
      }
      if (this.otpNumbersGeneral[index] !== '' && index < this.otpNumbersGeneral.length - 1) {
        this.$refs.otpBoxGeneral.querySelectorAll('input')[index + 1].focus();
    }
    },
    validateInputUser(index) {
      const inputValue = this.otpNumbersUser[index];
      const isValid = /^\d$/.test(inputValue); 
      if (!isValid) {
        this.otpNumbersUser[index] = ''; 
      }
      if (this.otpNumbersUser[index] !== '' && index < this.otpNumbersUser.length - 1) {
        this.$refs.otpBoxUser.querySelectorAll('input')[index + 1].focus();
    }
    },
    startTimerHcp() {
      if (this.timeLeftHcp > 0) {
      this.disableHcpBtn=false
      this.timegreaterHcp=false
    this.timerHcp = setInterval(() => {
      this.timeLeftHcp--;
      if (this.timeLeftHcp == 0) {
      this.timegreaterHcp=true
        clearInterval(this.timerHcp);
        this.enableResendBtnHcp = true;
        this.sendOTPHcp = false;
      }
    }, 1000);
  }
    },
    startTimerGeneral() {
      if (this.timeLeftGeneral > 0) {
      this.disableGeneralBtn=false
      this.timegreaterGeneral=false
    this.timerGeneral = setInterval(() => {
      this.timeLeftGeneral--;
      if (this.timeLeftGeneral == 0) {
      this.timegreaterGeneral=true
        clearInterval(this.timerGeneral);
        this.enableResendBtnGeneral = true;
        this.sendOTPGeneral = false;
      }
    }, 1000);
  }
    },
    startTimerUser() {
      if (this.timeLeftUser > 0) {
      this.disableUserBtn=false
      this.timegreaterUser=false
    this.timerUser = setInterval(() => {
      this.timeLeftUser--;
      if (this.timeLeftUser == 0) {
      this.timegreaterUser=true
        clearInterval(this.timerUser);
        this.enableResendBtnUser = true;
        this.sendOTPUser = false;
      }
    }, 1000);
  }
    },
    closeQuestionBox(){
      this.isAdmin=false;
      this.adminAnswaer="";
    },
    validateAgeDoctor() {
  this.doctorage = this.doctorage.replace(/[^0-9]/g, '');
  if (this.doctorage.startsWith('-')) {
    this.doctorage = '';
  }
},
validateAgePublic() {
  this.publicage = this.publicage.replace(/[^0-9]/g, '');
  if (this.publicage.startsWith('-')) {
    this.publicage = '';
  }
},
    getDoctorHistory(){
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
       axios.get(`${BASE_API_URL}/doctor-history`)
         .then( (response) => {return response })
    },
    openTermsConditionHCP() {
      window.open('/terms/doctor')
    },
    openTermsConditionUser() {
      window.open('/terms/public')
    },
    openPrivacyPolicy() {
      window.open('/terms/privacypolicy')
    },
    routeBack() {
     if( this.queryParameters.routeName ==='joinpatient'){
        window.location.href='/doctor/patientgroups';
      }else if(this.queryParameters.routeName ==='receiveconsultation'){
        window.location.href='/doctor/consultationrequests';
      }else if(this.queryParameters.routeName ==='getcase'){
        window.location.href='/doctor/communities';
      }else if(this.queryParameters.routeName ==='offerconsultation'){
        window.location.href='/doctor/offerconsultation';
      }else if(this.queryParameters.routeName ==='accessclinical'){
        window.location.href='/doctor/clinicalresources';
      }else if(this.queryParameters.routeName ==='previewPage-clinic' || this.queryParameters.routeName ==='previewPage-home'){
        window.location.href='/public/searchclinic';
      }else if(this.queryParameters.routeName ==='joindoctorscommunity'){
        window.location.href='/public/doctorcommunities';
      }else if(this.queryParameters.routeName ==='joinpatientgroups'){
        window.location.href='/public/patientgroups';
      }else if(this.queryParameters.routeName ==='accessreliableinformation'){
        window.location.href='/public/patientresources';
      }
      else if(this.queryParameters.routeName ==='medi-records'){
        window.location.href='/public';
      }
      else{
      window.location.href = "/";
      }
    },
    publicPage() {
      window.location.href = "/";
    },
  getDemoDoctor(mobileNumber){
    const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
    let payload={
     mobile:mobileNumber
      }
        axios
    .post(`${BASE_API_URL}/hcps/get-sign`, payload)
    .then((response) => {
      if(response.data){
        this.doctorPayload=response.data
        if(this.doctorPayload.is_doctorsession==false){
          this.getAdminQuestion(this.doctorPayload);
        }else if(this.doctorPayload.is_doctorsession==true && this.doctorPayload.doctorunique_id != this.doctorUniqueID){
    Swal.fire({
    title: "There is an active session. Log off that session?",
    confirmButtonText: "OK",
    cancelButtonText: "Cancel",
    showCancelButton: true, // Show the Cancel button
    }).then((result) => {
    if (result.isConfirmed) {
   axios
  .post(`${BASE_API_URL}/generateotp`, payload)
  .then((response)=>{
    if(response.data.type === 'success'){
  let updatePayload={
    is_doctorsession :false,
    doctorunique_id:''}
    axios
    .put(`${BASE_API_URL}/hcps/${this.doctorPayload.id}/update`, updatePayload, )
    .then((response)=>{
      if(response.data){
        this.getAdminQuestion(this.doctorPayload);
        localStorage.removeItem("id");
      }
    })
  }})
    }
    });}else{
      this.doctorUpdateCall(this.doctorPayload);
    } }
  })
  },
 getAdminQuestion(payloadData){
    const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
    this.adminQuestionData=[]
    this.isAdmin=true
    axios
    .get(`${BASE_API_URL}/adminquestion`)
    .then((response)=>{
        response.data.map((item)=>{
          if(item.admin_id===payloadData.id){
          this.adminQuestionData.push(item)
          }
        })
      const firstElement = this.adminQuestionData[0];
      const lastElement = this.adminQuestionData[this.adminQuestionData.length - 1];
      function randomIntFromInterval(min, max) { 
      return Math.floor(Math.random() * (max - min + 1) + min)
      }
    const rndInt = randomIntFromInterval(firstElement.questionNumber, lastElement.questionNumber)
    this.adminQuestionData.map((value)=>{
    if(value.questionNumber===rndInt){
    this.adminQA={
      adminid:value.admin_id,
      id:value.id,
      options:value.options,
      questions:value.questions,
      questionNumber:value.questionNumber
      }
    }
    })}) 
    },
    getAnswer(){
      if(this.adminQA.options===this.adminAnswaer.toLowerCase()){
      if(this.doctorPayload.mobile === '9182701237'){
      localStorage.setItem('id', this.doctorPayload.id)
      localStorage.setItem('doctor-uniqueId',this.doctorRandomUUID)
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      let updatePayload={
        is_doctorsession :true,
        doctorunique_id:this.doctorRandomUUID
        }
        axios
        .put(`${BASE_API_URL}/hcps/${this.doctorPayload.id}/update`, updatePayload, )
        .then((response)=>{
          if(response.data){
            this.$router.push({ path: '/doctor/index' });
          }})
      }else{
      localStorage.setItem('AdminsignIn', this.adminPayload.id)
      localStorage.setItem('admin-uniqueId',this.adminRandomUUID)
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      let updatePayload={
      is_adminsession:true,
      adminunique_id :this.adminRandomUUID
      }
      axios
      .put(`${BASE_API_URL}/admin/${this.adminPayload.id}/update`, updatePayload, )
      .then((response)=>{
        if(response.data){
          this.$router.push({ path: '/admin/index' });
        }})
      }
      } else{
      Swal.fire({
      title: "Unauthorized attempt to Sign-in. You could be penalized.",
      confirmButtonColor: "#7066e0",
      confirmButtonText: "OK",
      }).then((result) => {
      if (result.isConfirmed) {
      this.mobileHcp='',
      this.mobileUser='',
      this.mobileGeneral=''
      this.isAdmin=false
      this.adminAnswaer=''
      }
      });
      }       
      },
    adminUpdateCall(adminData){
      localStorage.setItem('AdminsignIn',adminData.id)
      localStorage.setItem('admin-uniqueId',this.adminRandomUUID)
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
    let updatePayload={
      is_adminsession:true,
      adminunique_id :this.adminRandomUUID
      }
      axios
      .put(`${BASE_API_URL}/admin/${this.adminPayload.id}/update`, updatePayload, )
      .then((response)=>{
        if(response.data){
          this.$router.push({ path: '/admin/index' });
        }
        })
    },
    adminSessionAlert(usecaseData){
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      Swal.fire({
  title: "There is an active session. Log off that session?",
  confirmButtonText: "OK",
  cancelButtonText: "Cancel",
  showCancelButton: true, // Show the Cancel button
}).then((result) => {
  if (result.isConfirmed) {
    localStorage.removeItem("AdminsignIn");
    let updatePayload={
          is_adminsession :false,
          adminunique_id:''
      }
      axios
      .put(`${BASE_API_URL}/admin/${usecaseData.id}/update`, updatePayload, )
      .then((response)=>{
        if(response.data){
          this.getAdminQuestion(usecaseData);
        }
      })
    }
      });
    },
    generateOTPGeneral() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      let payload = {
        mobile: this.mobileGeneral,
      };
  axios
  .post(`${BASE_API_URL}/admin/get-sign`, payload)
  .then((response) => {
    this.adminPayload = response.data;
    if (this.mobileGeneral===this.adminPayload?.mobile && this.adminPayload?.role === "admin") {
    if(this.adminPayload.is_adminsession == false ){
      this.getAdminQuestion(this.adminPayload);
      }
      else if(this.adminPayload.is_adminsession == true && this.adminPayload.adminunique_id != this.adminUniqueID ){
      this.adminSessionAlert(this.adminPayload)
      }
      else if(this.adminPayload.is_adminsession == true && this.adminPayload.adminunique_id == this.adminUniqueID ){
        this.adminUpdateCall(this.adminPayload)
      }
    }
    else if(this.mobileGeneral==='9182701237'){
      this.getDemoDoctor(this.mobileGeneral);
    }
    else  {
      axios
  .post(`${BASE_API_URL}/hcps/get-sign`, payload)
  .then((response) => {
    this.doctorPayload = response.data;
    axios
  .post(`${BASE_API_URL}/public-user/get-sign`, payload)
  .then((response) => {
    this.userPayload = response.data;
    if(this.mobileGeneral === this.doctorPayload?.mobile && this.doctorPayload?.role === 'hcp'){
       if( this.doctorPayload.is_doctorsession == false ){
    axios
      .post(`${BASE_API_URL}/generateotp`, payload)
      .then((response)=>{
        if(response.data.type === 'success'){
        this.startTimerGeneral();
        this.sendOTPGeneral=true
      this.showAlertTxt = false;
      setTimeout(() =>{
              this.$refs.otpBoxGeneral.querySelectorAll('input')[0].focus()
          }, 100)
      }
    })
  }
  else if( this.doctorPayload.is_doctorsession == true && this.doctorPayload.doctorunique_id != this.doctorUniqueID){
    Swal.fire({
  title: "There is an active session. Log off that session?",
  confirmButtonText: "OK",
  cancelButtonText: "Cancel",
  showCancelButton: true, // Show the Cancel button
}).then((result) => {
  if (result.isConfirmed) {
        axios
      .post(`${BASE_API_URL}/generateotp`, payload)
      .then((response)=>{
        if(response.data.type === 'success'){
    localStorage.removeItem("id");
    let updatePayload={
      is_doctorsession :false,
      doctorunique_id:''
    }
    axios
    .put(`${BASE_API_URL}/hcps/${this.doctorPayload.id}/update`, updatePayload, )
    .then((response)=>{
      if(response.data){
        this.startTimerGeneral();
      this.sendOTPGeneral=true
    this.showAlertTxt = false;
    setTimeout(() =>{
            this.$refs.otpBoxGeneral.querySelectorAll('input')[0].focus()
        }, 100)
      }
        })
      }})
      }
      });
  }
  else{
    this.doctorUpdateCall(this.doctorPayload);
  }
  }
  else if(this.mobileGeneral === this.userPayload?.mobile  && this.userPayload?.role=='user'){
    if( this.userPayload.is_publicsession == false){
  axios
      .post(`${BASE_API_URL}/generateotp`, payload)
      .then((response)=>{
        if(response.data.type === 'success'){
        this.startTimerGeneral();
        this.sendOTPGeneral=true
      this.showAlertTxt = false;
      setTimeout(() =>{
              this.$refs.otpBoxGeneral.querySelectorAll('input')[0].focus()
          }, 100)
      }
    })
  }
  else if(this.userPayload.is_publicsession == true && this.userPayload.publicunique_id != this.publicUniqueID){
    Swal.fire({
  title: "There is an active session. Log off that session?",
  confirmButtonText: "OK",
  cancelButtonText: "Cancel",
  showCancelButton: true, // Show the Cancel button
}).then((result) => {
  if (result.isConfirmed) {
        axios
      .post(`${BASE_API_URL}/generateotp`, payload)
      .then((response)=>{
        if(response.data.type === 'success'){
    localStorage.removeItem("publicSignUpData");
    let updatePayload={
          is_publicsession :false,
          publicunique_id:''
    }
    axios
    .put(`${BASE_API_URL}/public-user/${this.userPayload.id}/update`, updatePayload, )
    .then((response)=>{
      if(response.data){
        this.startTimerGeneral();
      this.sendOTPGeneral=true
    this.showAlertTxt = false;
    setTimeout(() =>{
            this.$refs.otpBoxGeneral.querySelectorAll('input')[0].focus()
        }, 100)
      }
        })
      }})
      }
      });
  }
  else {
    this.userUpdateCall(this.userPayload);
  }
  }
  else{
    this.showAlertTxt = true;
    this.mobileHcp=this.mobileGeneral;
    this.mobileUser=this.mobileGeneral;
    this.mobileGeneral='';
  }
  })
    })
      }
    })
    },
    verityOTPGeneral(){
    const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
    let payload = {
    mobile: this.mobileGeneral,
    };
    axios
      .get(`${BASE_API_URL}/verifyotp/${this.combinedOTPGeneral}/${this.mobileGeneral}`)
      .then((response) => {
        if(response.data.type === 'success'){
      axios
      .post(`${BASE_API_URL}/hcps/get-sign`, payload)
.then((response) => {
  this.doctorPayload = response.data;
  axios
.post(`${BASE_API_URL}/public-user/get-sign`, payload)
.then((response) => {
  this.userPayload = response.data;
  if (this.doctorPayload) {
    if (this.mobileGeneral === this.doctorPayload.mobile && this.doctorPayload.role === "hcp") {
        this.doctorUpdateCall(this.doctorPayload);
    }
    }else  if (this.userPayload) {
    if (this.mobileGeneral === this.userPayload.mobile && this.userPayload.role === "user") {
      this.userUpdateCall(this.userPayload);
    }
  }
  })})
      }
      else{
        this.$swal("Please enter valid otp");
      }
    })
      },
      resendOTPGeneral(){
        const BASE_API_URL = process.env.VUE_APP_BASE_APIURL

       axios
       .get(`${BASE_API_URL}/resendotp/${this.mobileGeneral}`)
         .then((response)=>{
           if(response.data.type === 'success'){
      this.otpNumbersGeneral= ['', '', '', '']
      this.timeLeftGeneral=30;
      this.startTimerGeneral();
      this.sendOTPGeneral=true
      setTimeout(() =>{
      this.$refs.otpBoxGeneral.querySelectorAll('input')[0].focus()
      }, 100)
         }
       })
      },
      doctorUpdateCall(doctorData){
      localStorage.setItem('id', doctorData.id)
      localStorage.setItem('doctor-uniqueId',this.doctorRandomUUID)
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      let updatePayload={
        is_doctorsession :true,
          doctorunique_id:this.doctorRandomUUID
        }
        axios
        .put(`${BASE_API_URL}/hcps/${doctorData.id}/update`, updatePayload, )
        .then((response)=>{
          if(response.data){
      if(this.queryParameters.routeName){
      this.doctorPracticeArea = this.doctorPracticeArea.concat(this.doctorPayload.practiceId);
      this.ConsultationPracticeArea = this.ConsultationPracticeArea.concat(this.doctorPayload.practiceId)
      this.doctorConsultations = this.doctorConsultations.concat(this.doctorPayload.Doctorconsultaion)
      this.doctorLibraryPracticeArea = this.doctorLibraryPracticeArea.concat(this.doctorPayload.practiceId)
      this.doctorPatientGroups = this.doctorPatientGroups.concat(this.doctorPayload.doctorPatientId)
      const doctorSigninPayload ={
      doctorPatientId: this.doctorPatientGroups,
      practiceId: this.queryParameters.routeName === 'getcase' ?  this.doctorPracticeArea :this.queryParameters.routeName === 'accessclinical' ?  this.doctorLibraryPracticeArea: this.ConsultationPracticeArea,
      Doctorconsultaion:this.doctorConsultations, 
      updated_by:'doctor',  
      }
      axios
      .put(`${BASE_API_URL}/hcps/${doctorData.id}/update`, doctorSigninPayload)
      .then(async (response) => {
      if (response?.status == 200) {
      await this.getDoctorHistory();
      this.$router.push({ path: '/doctor/index' });
      }})
      }
      else {
      this.$router.push({ path: '/doctor/index' });
      }
    }})
    },
      generateOTPHcp() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      let payload = {
      mobile: this.mobileHcp,
      };

      axios
      .post(`${BASE_API_URL}/admin/get-sign`, payload, {
      headers: {
      "Content-Type": "application/json",
      },
      })
      .then((response) => {
      this.adminPayload = response.data;
      if (this.mobileHcp===this.adminPayload?.mobile && this.adminPayload?.role === "admin") {
      if(this.adminPayload.is_adminsession == false ){
        this.getAdminQuestion(this.adminPayload);
        }
       else if(this.adminPayload.is_adminsession == true && this.adminPayload.adminunique_id != this.adminUniqueID ){
       this.adminSessionAlert(this.adminPayload)
        }
        else if(this.adminPayload.is_adminsession == true && this.adminPayload.adminunique_id == this.adminUniqueID ){
          this.adminUpdateCall(this.adminPayload)
        }
    }
      else if(this.mobileHcp==='9182701237'){
        this.getDemoDoctor(this.mobileHcp);
      }
      else  {
        axios
  .post(`${BASE_API_URL}/hcps/get-sign`, payload)
  .then((response) => {
    this.doctorPayload = response.data;
    axios
.post(`${BASE_API_URL}/public-user/get-sign`, payload)
.then((response) => {
  this.userPayload = response.data;
  if(this.mobileHcp === this.doctorPayload?.mobile && this.doctorPayload?.role === 'hcp' && this.doctorPayload?.is_doctorsession == true && this.doctorPayload.doctorunique_id != this.doctorUniqueID){
Swal.fire({
  title: "There is an active session. Log off that session?",
  confirmButtonText: "OK",
  cancelButtonText: "Cancel",
  showCancelButton: true, // Show the Cancel button
}).then((result) => {
  if (result.isConfirmed) {
        axios
      .post(`${BASE_API_URL}/generateotp`, payload)
      .then((response)=>{
        if(response.data.type === 'success'){
    let updatePayload={
      is_doctorsession :false,
      doctorunique_id:''
        }
        axios
        .put(`${BASE_API_URL}/hcps/${this.doctorPayload.id}/update`, updatePayload, )
        .then((response)=>{
          if(response.data){
            this.startTimerHcp();
      this.sendOTPHcp=true
      setTimeout(() =>{
      this.$refs.otpBoxHcp.querySelectorAll('input')[0].focus()
      }, 100)
            localStorage.removeItem("id");
          }
        })
      }})
      }
      });
  }
  else if(this.mobileHcp === this.doctorPayload?.mobile && this.doctorPayload?.role === 'hcp' && this.doctorPayload?.is_doctorsession == true && this.doctorPayload.doctorunique_id == this.doctorUniqueID){
    this.doctorUpdateCall(this.doctorPayload);
  }
 else if(this.mobileHcp === this.userPayload?.mobile  && this.userPayload?.role=='user' && this.userPayload?.is_publicsession == true && this.userPayload.publicunique_id != this.publicUniqueID){
Swal.fire({
  title: "There is an active session. Log off that session?",
  confirmButtonText: "OK",
  cancelButtonText: "Cancel",
  showCancelButton: true, // Show the Cancel button
}).then((result) => {
  if (result.isConfirmed) {
        axios
      .post(`${BASE_API_URL}/generateotp`, payload)
      .then((response)=>{
        if(response.data.type === 'success'){
    let updatePayload={
    is_publicsession :false,
    publicunique_id:''
      }
      axios
      .put(`${BASE_API_URL}/public-user/${this.userPayload.id}/update`, updatePayload, )
      .then((response)=>{
        if(response.data){
          this.startTimerHcp();
    this.sendOTPHcp=true
    setTimeout(() =>{
    this.$refs.otpBoxHcp.querySelectorAll('input')[0].focus()
    }, 100)
          localStorage.removeItem("id");
        }
      })
      }})
      }
      });
  }
  else if(this.mobileHcp === this.userPayload?.mobile  && this.userPayload?.role=='user' && this.userPayload?.is_publicsession == true && this.userPayload.publicunique_id == this.publicUniqueID){
    this.userUpdateCall(this.userPayload);
  }else{
      axios
      .post(`${BASE_API_URL}/generateotp`, payload)
      .then((response)=>{
        if(response.data.type === 'success'){
    this.startTimerHcp();
      this.sendOTPHcp=true
      this.showAlertTxt = false;
      setTimeout(() =>{
      this.$refs.otpBoxHcp.querySelectorAll('input')[0].focus()
      }, 100)
      }
    })
  }
  })
    })
      }
      })
      },
    verityOTPHcp(){
    const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
    let payload = {
    mobile: this.mobileHcp,
    };
    axios
      .get(`${BASE_API_URL}/verifyotp/${this.combinedOTPHcp}/${this.mobileHcp}`)
      .then((response) => {
        if(response.data.type === 'success'){
    axios
    .post(`${BASE_API_URL}/hcps/get-sign`, payload)
    .then((response) => {
      this.doctorPayload =response.data
      axios
    .post(`${BASE_API_URL}/public-user/get-sign`, payload)
    .then((response) => {
    this.userPayload = response.data;
    if (this.doctorPayload) {
    if (this.mobileHcp === this.doctorPayload.mobile && this.doctorPayload.role === "hcp") {
      this.doctorUpdateCall(this.doctorPayload);  
    }
    }else if (this.userPayload) {
    if (this.mobileHcp === this.userPayload.mobile && this.userPayload.role === "user") {
      this.userUpdateCall(this.userPayload);  
    }
    }
    else if (this.doctorPayload == null && this.userPayload == null ) {
    this.doctorOtpSuccess=true
    this.doctorSignup=false
    }
  })
    })
      }
      else{
        this.$swal("Please enter valid otp");
      }
    })
    },
      resendOTPHcp(){
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL

      axios
      .get(`${BASE_API_URL}/resendotp/${this.mobileHcp}`)
        .then((response)=>{
         if(response.data.type === 'success'){
      this.otpNumbersHcp= ['', '', '', '']
      this.timeLeftHcp=30;
      this.startTimerHcp();
      this.sendOTPHcp=true
      setTimeout(() =>{
      this.$refs.otpBoxHcp.querySelectorAll('input')[0].focus()
      }, 100)
       }
      })
      },
      userUpdateCall(publicData){
      localStorage.setItem('publicSignUpData',publicData.id)
      localStorage.setItem('public-uniqueId',this.publicRandomUUID)
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      let updatePayload={
        is_publicsession:true,
        publicunique_id :this.publicRandomUUID
        }
        axios
        .put(`${BASE_API_URL}/public-user/${publicData.id}/update`, updatePayload, )
        .then((response)=>{
          if(response.data){
        if(this.queryParameters.routeName){
      this.publicDoctorCommunityData = this.publicDoctorCommunityData.concat(this.userPayload.doctorsID)
      this.publicPatientGroupsData = this.publicPatientGroupsData.concat(this.userPayload.publicPatientId)
      this.doctorClinicData = this.doctorClinicData.concat(this.userPayload.clinicDetails)
      this.doctorHomeData=this.doctorHomeData.concat(this.userPayload.homeDetails)
      this.getDisorderArray = this.getDisorderArray.concat(this.userPayload.getDisordersId)
      const signInPayload ={
      doctorsID: this.publicDoctorCommunityData,
      publicPatientId: this.publicPatientGroupsData,
      clinicDetails: this.doctorClinicData,
      homeDetails:this.doctorHomeData,
      getDisordersId: this.getDisorderArray,
      }
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      axios
      .put(`${BASE_API_URL}/public-user/${this.userPayload.id}/update`, signInPayload, {
      headers: {
      "Content-Type": "application/json",
      },
      })
      .then((response) => {
      if (response?.data) {
      if(this.queryParameters.routeName==='previewPage-clinic') {
      const data = JSON.parse(this.queryParameters.doctorClinicDetails);
      const doctorClinicID = data.id;
      const doctorId= data.hcps_id.id;
      this.$router.push(`/user/preview?hcpId=${doctorId}&clinicId=${doctorClinicID}`)
      } 
      else if (this.queryParameters.routeName==='previewPage-home') {
      const data = JSON.parse(this.queryParameters.homeClinic)
      const doctorHomeID=data.id
      const doctorId= data.doctorId.id;
      this.$router.push(`/homeConsultation/preview?hcpId=${doctorId}&homeId=${doctorHomeID}`);
      }
      else if (this.queryParameters.publcLibraryId) {
      this.$router.push({ path: '/user/library' });
      }
      else if (this.queryParameters.publicMediId) {
      this.$router.push({ name: 'User-Upload-Record', params: { id: response.data.id, role: response.data.role } });
      } 
      else {
      this.$router.push({ path: '/user/index' });
      }
      }})
      }
      else {
      this.$router.push({ path: '/user/index' });
      }
    }})
      },
      generateOTPUser() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      let payload = {
      mobile: this.mobileUser,
      };
      axios
      .post(`${BASE_API_URL}/admin/get-sign`, payload, {
      headers: {
      "Content-Type": "application/json",
      },
      })
      .then((response) => {
      this.adminPayload = response.data;
      if (this.mobileUser===this.adminPayload?.mobile && this.adminPayload?.role === "admin") {
      if(this.adminPayload.is_adminsession == false ){
        this.getAdminQuestion(this.adminPayload);
        }
       else if(this.adminPayload.is_adminsession == true && this.adminPayload.adminunique_id != this.adminUniqueID ){
       this.adminSessionAlert(this.adminPayload)
        }
        else if(this.adminPayload.is_adminsession == true && this.adminPayload.adminunique_id == this.adminUniqueID ){
          this.adminUpdateCall(this.adminPayload)
        }
    }
      else if(this.mobileUser==='9182701237'){
        this.getDemoDoctor(this.mobileUser);
      }
      else {
        axios
  .post(`${BASE_API_URL}/hcps/get-sign`, payload)
  .then((response) => {
    this.doctorPayload = response.data;
    axios
.post(`${BASE_API_URL}/public-user/get-sign`, payload)
.then((response) => {
  this.userPayload = response.data;
if(this.mobileUser === this.doctorPayload?.mobile && this.doctorPayload?.role === 'hcp' && this.doctorPayload?.is_doctorsession == true && this.doctorPayload.doctorunique_id != this.doctorUniqueID){
Swal.fire({
  title: "There is an active session. Log off that session?",
  confirmButtonText: "OK",
  cancelButtonText: "Cancel",
  showCancelButton: true, // Show the Cancel button
}).then((result) => {
  if (result.isConfirmed) {
         axios
      .post(`${BASE_API_URL}/generateotp`, payload)
      .then((response)=>{
        if(response.data.type === 'success'){
    let updatePayload={
      is_doctorsession :false,
      doctorunique_id:''
        }
        axios
        .put(`${BASE_API_URL}/hcps/${this.doctorPayload.id}/update`, updatePayload, )
        .then((response)=>{
          if(response.data){
            this.startTimerUser();
      this.sendOTPUser=true
      setTimeout(() =>{
      this.$refs.otpBoxUser.querySelectorAll('input')[0].focus()
      }, 100)
            localStorage.removeItem("id");
          }
        })
      }})
      }
      });
  }
  else if(this.mobileUser === this.doctorPayload?.mobile && this.doctorPayload?.role === 'hcp' && this.doctorPayload?.is_doctorsession == true && this.doctorPayload.doctorunique_id == this.doctorUniqueID){
    this.doctorUpdateCall(this.doctorPayload);
  }
  else if(this.mobileUser === this.userPayload?.mobile  && this.userPayload?.role=='user' && this.userPayload?.is_publicsession == true && this.userPayload.publicunique_id != this.publicUniqueID){
Swal.fire({
  title: "There is an active session. Log off that session?",
  confirmButtonText: "OK",
  cancelButtonText: "Cancel",
  showCancelButton: true, // Show the Cancel button
}).then((result) => {
  if (result.isConfirmed) {
        axios
      .post(`${BASE_API_URL}/generateotp`, payload)
      .then((response)=>{
        if(response.data.type === 'success'){
    let updatePayload={
      is_publicsession :false,
      publicunique_id:''
        }
        axios
        .put(`${BASE_API_URL}/public-user/${this.userPayload.id}/update`, updatePayload, )
        .then((response)=>{
          if(response.data){
            this.startTimerUser();
      this.sendOTPUser=true
      setTimeout(() =>{
      this.$refs.otpBoxUser.querySelectorAll('input')[0].focus()
      }, 100)
            localStorage.removeItem("publicSignUpData");
          }
        })
      }})
      }
      });
  }
  else if(this.mobileUser === this.userPayload?.mobile  && this.userPayload?.role=='user' && this.userPayload?.is_publicsession == true && this.userPayload.publicunique_id == this.publicUniqueID){
    this.userUpdateCall(this.userPayload);
  }else{
      axios
      .post(`${BASE_API_URL}/generateotp`, payload)
      .then((response)=>{
        if(response.data.type === 'success'){
      this.startTimerUser();
      this.sendOTPUser=true
      this.showAlertTxt = false;
      setTimeout(() =>{
      this.$refs.otpBoxUser.querySelectorAll('input')[0].focus()
      }, 100)
      
      }
    })
  }
  })
    })
      }
      })
      },
      verityOTPUser(){
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      let payload = {
      mobile: this.mobileUser,
      };
      axios
        .get(`${BASE_API_URL}/verifyotp/${this.combinedOTPUser}/${this.mobileUser}`)
        .then((response) => {
          if(response.data.type === 'success'){
      axios
      .post(`${BASE_API_URL}/hcps/get-sign`, payload)
      .then((response) => {
      this.doctorPayload = response.data;
      axios
      .post(`${BASE_API_URL}/public-user/get-sign`, payload)
      .then((response) => {
      this.userPayload = response.data;
      if (this.doctorPayload) {
      if (this.mobileUser === this.doctorPayload.mobile && this.doctorPayload.role === "hcp") {
        this.doctorUpdateCall(this.doctorPayload);
      }
      }
      else if (this.userPayload) {
        if (this.mobileUser === this.userPayload.mobile && this.userPayload.role === "user") {
      this.userUpdateCall(this.userPayload);
      }
      }
      else if (this.doctorPayload == null && this.userPayload == null) {
      this.publicOtpSuccess=true
      this.publicSignup=false
      }
     })
      })
         }else{
          this.$swal("Please enter valid otp");
         }
      })
      },
      resendOTPUser(){
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL   
       axios
       .get(`${BASE_API_URL}/resendotp/${this.mobileUser}`)
         .then((response)=>{
           if(response.data.type === 'success'){
      this.otpNumbersUser= ['', '', '', '']
      this.timeLeftUser=30;
      this.startTimerUser();
      this.sendOTPUser=true
      setTimeout(() =>{
      this.$refs.otpBoxUser.querySelectorAll('input')[0].focus()
      }, 100)
         }
       })
      },
      signinPopupStatus(event) {
      if (event) {
      this.isprove = false;
      }
      },
      isOpenSignin() {
      this.$emit('isOpen', true)
      this.isprove = true;
      },
      getAllPincode(searchTag) {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      let url = `${BASE_API_URL}/pincodes`;
      if (searchTag) {
      url = url + `?pincode=${searchTag}`
      axios
      .get(url)
      .then((response) => {
      this.options = response.data;
      const dataObj = response.data;
      this.pincodedata = dataObj.map(item => item.pincode);
      })
      .catch(function (error) {
      return error
      });
      }
      },
      async addTag(newTag) {
      await this.getAllPincode(newTag);
      },
      changePublicFirstName(){
      let name =this.firstNamePublic.toLowerCase()
      return name.charAt(0).toUpperCase() + name.slice(1);
      },
      changePublicLastName(){
      let name =this.lastNamePublic.toLowerCase()
      return name.charAt(0).toUpperCase() + name.slice(1);
      },
      async registerPublic(userType = null) {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      this.userRegisterPayload.role = userType;
      const generalInformation = {
      "id": 24,
      "disorder_id": "24",
      "disorder": "General Information"
      };
      const hasgeneralInfo = this.getDisorderArray.find(item => item.id === generalInformation.id);
      if(!hasgeneralInfo) {
      this.getDisorderArray.push(generalInformation)
      }
      this.userRegisterPayload = {
      firstName: this.changePublicFirstName(),
      lastName: this.changePublicLastName(),
      mobile: this.mobileUser,
      pincode: this.pincodePublic,
      age: this.publicage,
      gender: this.selectedGender,
      role: userType,
      publicunique_id:this.publicRandomUUID,
      is_publicsession:true,
      doctorsID: this.publicDoctorCommunityData,
      publicPatientId: this.publicPatientGroupsData,
      getDisordersId: this.getDisorderArray,
      clinicDetails: this.doctorClinicData,
      homeDetails: this.doctorHomeData,
      public_card_type: this.queryParameters.routeName === 'previewPage-clinic'? this.searchClinicId :this.queryParameters.routeName==='previewPage-home'?
      this.searchClinicId:this.queryParameters.routeName === 'joindoctorscommunity'? this.publicDoctorCommunityId :this.queryParameters.routeName === 'joinpatientgroups'? 
      this.publicPatientGroupId:this.queryParameters.routeName==='accessreliableinformation'? this.publicLibraryId :this.queryParameters.routeName==='medi-records'? this.publicMedicalId: '0'
      }
      if (this.userRegisterPayload) {
      await axios
      .post(`${BASE_API_URL}/public-user`, this.userRegisterPayload, {
      headers: {
      "Content-Type": "application/json",
      },
      })
      .then(response => {
        if(response.data){
        localStorage.setItem('publicSignUpData', response?.data.id)
        localStorage.setItem('public-uniqueId',response?.data.publicunique_id)
        if(this.queryParameters.routeName==='previewPage-clinic') {
        const data = JSON.parse(this.queryParameters.doctorClinicDetails);
        const doctorClinicID = data.id;
        const doctorId= data.hcps_id.id;
        this.$router.push(`/user/preview?hcpId=${doctorId}&clinicId=${doctorClinicID}`)
        } 
        else if (this.queryParameters.routeName==='previewPage-home') {
        const data = JSON.parse(this.queryParameters.homeClinic)
        const doctorHomeID=data.id
        const doctorId= data.doctorId.id;
        this.$router.push(`/homeConsultation/preview?hcpId=${doctorId}&homeId=${doctorHomeID}`);
        }
        else if (response.data?.public_card_type === 4) {
        this.$router.push(`/user/library`);
        }
        else if (response.data && response.data.public_card_type === 5) {
        this.$router.push({ name: 'User-Upload-Record', params: { id: response.data.id, role: response.data.role } });
        } 
        else {
        this.$router.push(`/user/index`);
        }
        }
      })
      }
      },
      changeDoctorFirstName(){
      let name =this.firstNameDoctor.toLowerCase()
      return name.charAt(0).toUpperCase() + name.slice(1);
      },
      changeDoctorLastName(){
      let name =this.lastNameDoctor.toLowerCase()
      return name.charAt(0).toUpperCase() + name.slice(1);
      },
      async registerDoctor(userType = null) {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      this.doctorRegisterPayload.role = userType;
      this.doctorRegisterPayload = {
      firstName: this.changeDoctorFirstName(),
      lastName: this.changeDoctorLastName(),
      mobile: this.mobileHcp,
      pincode: this.pincodeDoctor,
      age: this.doctorage,
      role: userType,
      updated_by:'doctor',
      doctorunique_id:this.doctorRandomUUID,
      is_doctorsession:true,
      gender: this.selectedGender,
      practiceId: this.queryParameters.routeName === 'getcase' ?  this.doctorPracticeArea :this.queryParameters.routeName === 'accessclinical' ?  this.doctorLibraryPracticeArea: this.ConsultationPracticeArea,
      doctorPatientId: this.doctorPatientGroups,
      Doctorconsultaion:this.doctorConsultations,
      doctor_card_type:this.queryParameters.routeName === 'joinpatient' ? this.doctorPatientGroupId: this.queryParameters.routeName=== 'receiveconsultation' ? this.doctorReceiveConsultationId :this.queryParameters.routeName === 'getcase' ? this.doctorGetCaseId:this.queryParameters.routeName=== 'offerconsultation' ? this.doctorOfferConsultationId:this.queryParameters.routeName==='accessclinical'? this.doctorLibraryId :'0'
      };
      if (this.doctorRegisterPayload) {

      await axios
      .post(`${BASE_API_URL}/hcps`, this.doctorRegisterPayload, {
      headers: {
      "Content-Type": "application/json",
      },
      })
      .then(response => {
        if(response.data){
        localStorage.setItem('id', response?.data.id)
        localStorage.setItem('doctor-uniqueId',response?.data.doctorunique_id)
        this.$router.push({ path: '/doctor/index' });
      }
      }).catch(err => {
      return err
      })
      }
      },


      }
}
</script>
<style>
fieldset.signUpField{
  border: 2px solid #00979e !important;
    background-color: #fff;
    width: 425px;
    margin-left: auto;
    margin-right: auto;
    padding: 1rem;
}
fieldset.signUpField legend{
  background: #fff;
  padding: 4px 10px;
  /* color: #000; */
  margin: 0 auto;
  display: block;
  float: none;
  width: auto;
  border: 2px solid #00979e !important;
font-size: 25px;
border-radius: 4px;
}
.SignupWhiteBtn{
border: 2px solid #00979e !important;
font-size: 25px;
}
.disable-btn{
  background-color: #cccccc !important;
}
.userBtn{
  font-size: 32px;
  width: 425px;
  border-radius: 0px;
  text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    border: 2px solid #00979e;
    background-color: #00979e;
    cursor: pointer;
    color: #fff;
    border-radius: 5px;
    padding: 7px;
}
#countdowntimer{
    color: red;
}
  .bottom-link-navbar{
    display: block !important;
  }
.modal-dialog {
    align-items: center;
    display: grid;
    height: inherit;
}
li.nav-item.nav {
    display: block !important;
  }

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.closebuttonContainer {
  margin-top: 7.5rem;
}

.cursorPointer {
  cursor: pointer;
}
.redborder {
  border: 2px solid red !important;
}

.sign-up-feilds {
  font-size: 20px;
}

.sign-up-in-text {
  font-size: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.signup-tabs {
  display: flex;
  justify-content: flex-start;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5rem;
  width: 425px;
}

.nav-tabs .nav-link.active.signTab {
  color: white;
  background-color: #00979e;
  border-color: #dee2e6 #dee2e6 #fff;
  font-size: 32px;
}

.nav-tabs .nav-link.signTab {
  color: black;
  /* margin-bottom: -1px; */
  background: 0 0;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  font-size: 32px;
}

.navbar-light .navbar-nav .nav-link {
  color: #00979e;
}

button.btn.btn-color.text-white.mt-3 a {
  color: #fff;
}

button.btn.btn.text-blue.mt-3 a {
  color: #34989F;
}

.inputs.d-flex.flex-row.justify-content-center.mt-2 {
  margin: auto;
  width: 80%;
}

.btn.btn-color.text-white.mt-3 {
  font-weight: bold;
  height: 45px;
  width: 459px;
  margin-left: auto;
  width: 94%;
  margin-right: auto;
}

.btn.text-blue.mt-3 {
  font-weight: bold;
  height: 45px;
  color: #34989f;
  background-color: #fff;
  margin-left: auto;
  width: 94%;
  margin-right: auto;
}

.m-2 .text-center .form-control .rounded {
  border-color: #34989f;
  width: 35px !important;
}

.form-check-box {
  border: #34989f !important;
  height: 20px;
  /* width: 16px; */
}

.form-control {
  text-align: center;
  border-color: #34989f
}

.text-font {
  font-weight: bold;
  text-align: center;

}

section.signupBox {
  border: 2px solid #00979e !important;
  background-color: #fff;
  width: 425px;
  margin-left: auto;
  margin-right: auto;
  /* margin-top: 10rem; */
}


.border-color.p-5.form1 {
  padding: 49px !important;
  padding-top: 35px !important;

}

.form-check-label.text-color {
  font-size: 12px;
  margin-top: 4px;
  margin-left: 4px;
}

.agreeCheckbox {
  display: flex;
  justify-content: center;
}

.checkbox h6 {
  font-size: 13px;
  padding-left: 5px;
}

.forsignup-btn {
  outline: none;
  border: none;
  background-color: #34989f;
  color: #fff;
  padding: 9px;
}

#doctor .btn:disabled{
  background: #cccccc !important;
}

#public .btn:disabled{
  background: #cccccc !important;
}

.allfieldcontent{
color: red;
margin-top: 15px
}

/* .move-page {
  display: block;
  position: relative;
  animation: mymove;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-duration: 1s;
}

@keyframes mymove {
  from {
    right: 0px
  }

  to {
    right: 300px
  }

} */
@media screen and (max-width:991px) {
  .userBtn{
  font-size: 27px;
  width: 350px;
}
  section.signupBox {
    margin-bottom: 5rem;
  }
  fieldset.signUpField{
  border: 2px solid #00979e !important;
    background-color: #fff;
    width: 425px;
    margin-left: auto;
    margin-right: auto;
    padding: 1rem;
}

  .closebuttonContainer {
    margin-top: 4rem;
  }
}

@media screen and (max-width:576px) {
  .closeButton{
    text-align: end !important;
  }

  .nav-tabs .nav-link.active.signTab {
    font-size: 23px;
  }

  .nav-tabs .nav-link.signTab {
    font-size: 23px;
  }

  .sign-up-in-text {
    font-size: 20px;
  }
  .userBtn{
    width: 271px;
    font-size: 24px;
    padding: 7px 18px 
  }

  .sign-up-feilds {
    font-size: 17px;
  }

  .termsFont {
    width: 320px;
  }

  .signup-tabs {
    width: 320px;
    margin-top: 3rem;
  }

  section.signupBox {
    width: fit-content;
  }
  fieldset.signUpField{
  width: fit-content;
}
}

@media screen and (max-width:404px) {
  .termsFont {
    width: auto;
  }

  .signup-tabs {
    width: auto;
  }
}

@media screen and (max-width:370px) {
  .termsFont {
    font-size: 13px;
  }
}
</style>